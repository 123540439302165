import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { JurisdictionI } from 'src/app/interfaces/plans.interface';
import { ApiJurisdiccionService } from 'src/app/services/api-jurisdiccion.service';
import { AppLogService } from 'src/app/services/appLog.service';
import { IUserData } from 'src/app/services/user/user-data.interface';

@Component({
  selector: 'app-form-real-address',
  templateUrl: './form-real-address.component.html',
  styleUrls: ['./form-real-address.component.scss'],
})
export class FormRealAddressComponent implements OnInit {
  @Input() public userData: IUserData;
  @Input() public addressType: string;
  @Input() jurisdictions: JurisdictionI[] = [];

  @Output() onSubmit = new EventEmitter<any>();

  title = 'domicilio ';
  subtitle: string;


  addressForm = this.fb.group({
    street: ['', Validators.required],
    streetNumber: ['', Validators.required],
    floor: [''],
    flat: [''],
    city: ['', Validators.required],
    province: ['', Validators.required],
    postalCode: ['', Validators.required],
    optionalDataType: [''],
    optionalData: [''],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private apiJurisdiccionS: ApiJurisdiccionService,
    private appLogger: AppLogService
  ) {}

  async ngOnInit() {
    await this.getJurisdictions();

    if (this.addressType === 'real') {
      this.title += 'real';
      this.subtitle = 'Lugar donde vivís. El que figura al dorso de tu DNI';
    } else {
      this.title += 'fiscal';
      this.subtitle = 'Lugar principal donde desarrollas tu actividad';
    }

    const addressType = 'domicilio_' + this.addressType;
    const selectedProv = this.jurisdictions.find((x) => x.id == this.userData?.[addressType]?.provincia.id);
    this.addressForm.patchValue({
      street: this.userData?.[addressType]?.calle,
      streetNumber: this.userData?.[addressType]?.numero,
      floor: this.userData?.[addressType]?.piso,
      flat: this.userData?.[addressType]?.departamento,
      city: this.userData?.[addressType]?.localidad,
      province: selectedProv,
      postalCode: this.userData?.[addressType]?.codigo_postal,
      optionalDataType: this.userData?.[addressType]?.tipo_dato_adicional,
      optionalData: this.userData?.[addressType]?.dato_adicional,
    });

    this.addressForm.updateValueAndValidity();

    const eventValue = { page: `Domicilio ${this.addressType}` };
    this.appLogger.logPageView(eventValue);
  }

  private getJurisdictions() {
    return new Promise<void>((resolve) => {
      this.apiJurisdiccionS.getJurisdictions().subscribe(
        (res) => {
          this.jurisdictions = res;
          resolve();
        },
        (err) => {
          console.error(err);
          resolve();
        }
      );
    });
  }

  save() {
    if (this.addressForm.valid) {
      this.onSubmit.emit(this.addressForm.value);
      return;
    }
  }
}
