// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-col {
  padding: 0 !important; /* Ajusta el padding de las columnas */
  padding-top: 16px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImJpcnRoZGF5LmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQVVFO0VBQ0UscUJBQUEsRUFBQSxzQ0FBQTtFQUNBLDRCQUFBO0FBVEoiLCJmaWxlIjoiYmlydGhkYXkuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvLyBpb24taXRlbSB7XG4vLyAgICAgcGFkZGluZzogMCAhaW1wb3J0YW50OyAvKiBRdWl0YSB0b2RvIGVsIHBhZGRpbmcgZGUgbG9zIGlvbi1pdGVtICovXG4vLyAgICAgbWFyZ2luOiAwOyAvKiBBc2Vnw7pyYXRlIGRlIHF1ZSB0YW1wb2NvIGhheWEgbcOhcmdlbmVzICovXG4vLyAgIH1cblxuLy8gICBpb24tcm93IHtcbi8vICAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7IC8qIFF1aXRhIGN1YWxxdWllciBwYWRkaW5nIGRlbCByb3cgKi9cbi8vICAgICBtYXJnaW46IDA7XG4vLyAgIH1cblxuICBpb24tY29sIHtcbiAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7IC8qIEFqdXN0YSBlbCBwYWRkaW5nIGRlIGxhcyBjb2x1bW5hcyAqL1xuICAgIHBhZGRpbmctdG9wOiAxNnB4ICFpbXBvcnRhbnQ7XG4gIH0iXX0= */`, "",{"version":3,"sources":["webpack://./src/app/components/shared/birthday/birthday.component.scss"],"names":[],"mappings":"AAUE;EACE,qBAAA,EAAA,sCAAA;EACA,4BAAA;AATJ;AACA,gzBAAgzB","sourcesContent":["// ion-item {\n//     padding: 0 !important; /* Quita todo el padding de los ion-item */\n//     margin: 0; /* Asegúrate de que tampoco haya márgenes */\n//   }\n\n//   ion-row {\n//     padding: 0 !important; /* Quita cualquier padding del row */\n//     margin: 0;\n//   }\n\n  ion-col {\n    padding: 0 !important; /* Ajusta el padding de las columnas */\n    padding-top: 16px !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
