import { Injectable } from '@angular/core';
import { AlertController, MenuController, ModalController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/common/providers/nav-controller';
import { filter, take } from 'rxjs/operators';
import { SectionPageENUM } from '../enums/section-page.enum';
import { AuthService } from './auth.service';
import { Country } from './country.service';
import { CurrentCountryService } from './current-country.service';
import { MonotributoService } from './monotributo.service';
import { NavigationService } from './navigation.service';
import { IUserData, UDEstateENUM } from './user/user-data.interface';
import { UserDataService } from './user/user-data.service';

export type MenuItem = {
  id: string;
  label: string;
  weight: number;
  icon?: string;
  img?: string;
  disabled: boolean;
  show: boolean;
  subMenu?: {
    show: boolean;
    items: SubMenuItem[];
  };
  action: () => void;
};

type SubMenuItem = Pick<MenuItem, 'id' | 'label' | 'disabled' | 'action' | 'subMenu' | 'img'>;

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  currentCountry: Country;

  constructor(
    private currentCountryService: CurrentCountryService,
    private navigationService: NavigationService,
    private authService: AuthService,
    private menuController: MenuController,
    private alertController: AlertController,
    private modalController: ModalController,
    private monotributoService: MonotributoService,
    private userDataService: UserDataService
  ) {}

  private navigateTo(page: string, options?: NavigationOptions) {
    this.menuController.close().then(() => this.navigationService.navigateRoot(page, options));
  }

  private buildEssentials() {
    /** Inicio **/
    const inicio: MenuItem = {
      id: 'inicio',
      label: 'Inicio',
      weight: -9,
      icon: 'home-outline',
      disabled: false,
      show: true,
      action: () => this.navigateTo('home'),
    };

    /** Mi Perfil **/
    const miPerfil: MenuItem = {
      id: 'mi_perfil',
      label: 'Mi Perfil',
      weight: -8,
      icon: 'person-outline',
      disabled: false,
      show: true,
      action: () => this.navigateTo('profile'),
    };

    /** Beneficios **/

    // const beneficios: MenuItem = {
    //   id: 'beneficios',
    //   label: 'Beneficios',
    //   weight: 7,
    //   icon: 'gift-outline',
    //   disabled: false,
    //   show: true,
    //   action: () => this.navigateTo('benefits'),
    // };

    /** Capacitacion **/
    // const capacitacion: MenuItem = {
    //   id: 'capacitacion',
    //   label: 'Aprendé',
    //   weight: 7,
    //   icon: 'easel-outline',
    //   disabled: false,
    //   show: true,
    //   action: () => this.navigateTo('tutorials'),
    // };

    /** Contacto **/
    const contacto: MenuItem = {
      id: 'contacto',
      label: 'Contacto',
      weight: 8,
      icon: 'chatbox-outline',
      disabled: false,
      show: true,
      action: () => this.navigateTo('contact'),
    };

    /** Salir **/
    const salir: MenuItem = {
      id: 'salir',
      label: 'Salir',
      icon: 'power-outline',
      weight: 9,
      disabled: false,
      show: true,
      action: () => {
        this.menuController.close().then(() => {
          this.authService.logout();
          location.reload();
        });
      },
    };

    return [inicio, miPerfil, contacto, salir];
  }

  /** Facturar **/
  private buildFacturarAr(userData: IUserData) {
    const facturar: MenuItem = {
      id: 'facturar',
      label: 'Factura',
      weight: 0,
      icon: 'calculator-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        facturar.subMenu.show = !facturar.subMenu.show;
      },
    };

    const personalizarFactura: SubMenuItem = {
      id: 'personalizar_factura',
      label: 'Personalizar',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/personalizar'),
    };

    const agendaContactos: SubMenuItem = {
      id: 'agenda_contactos',
      label: 'Agenda de contactos',
      disabled: false,
      action: () => this.navigateTo('management/contacts'),
    };
    if (userData?.plan_contratado_vigente?.plan?.id !== 10) {
      const facturar_rapido: SubMenuItem = {
        id: 'emitir_factura_facil',
        label: 'Rápida',
        disabled: false,
        action: () => this.navigateTo('servicios/facturacion/facturar_facil', { queryParams: { reset: 1 } }),
      };

      const emitirFactura: SubMenuItem = {
        id: 'emitir_factura',
        label: 'Clásica',
        disabled: false,
        action: () => this.navigateTo('servicios/facturacion/facturar', { queryParams: { reset: 1 } }),
      };

      const emitirFacturaE: SubMenuItem = {
        id: 'emitir_factura_e',
        label: 'Exportación',
        disabled: true,
        action: () => this.navigateTo('servicios/facturacion/facturar_e', { queryParams: { reset: 1 } }),
      };
      facturar.subMenu.items.push(facturar_rapido, emitirFactura, emitirFacturaE, personalizarFactura, agendaContactos);
    }
    if (userData?.plan_contratado_vigente?.plan?.id === 10) {
      const facturar_rapido: SubMenuItem = {
        id: 'emitir_factura_facil',
        label: 'Rápida',
        disabled: false,
        action: () => this.navigateTo('servicios/facturacion/facturar_facil', { queryParams: { reset: 1 } }),
      };

      const emitirFactura: SubMenuItem = {
        id: 'emitir_factura',
        label: 'Clásica',
        disabled: false,
        action: () => this.navigateTo('servicios/facturacion/facturar', { queryParams: { reset: 1 } }),
      };
      const emitirFacturaE: SubMenuItem = {
        id: 'emitir_factura_e',
        label: 'Exportacion',
        disabled: false,
        action: () => this.navigateTo('servicios/facturacion/facturar_e', { queryParams: { reset: 1 } }),
      };
      facturar.subMenu.items.push(facturar_rapido, emitirFactura, emitirFacturaE, personalizarFactura, agendaContactos);
    }
    return facturar;
  }
  private buildFacturarPe(userData: IUserData) {
    const facturar: MenuItem = {
      id: 'facturar',
      label: 'Facturar',
      weight: 0,
      icon: 'calculator-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        facturar.subMenu.show = !facturar.subMenu.show;
      },
    };

    const emitirFactura: SubMenuItem = {
      id: 'emitir_factura',
      label: 'Emitir comprobantes',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/facturar', { queryParams: { reset: 1 } }),
    };

    const personalizarFactura: SubMenuItem = {
      id: 'personalizar_factura',
      label: 'Personalizar',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/personalizar'),
    };

    const agendaContactos: SubMenuItem = {
      id: 'agenda_contactos',
      label: 'Agenda de contactos',
      disabled: false,
      action: () => this.navigateTo('management/contacts'),
    };

    facturar.subMenu.items.push(emitirFactura, personalizarFactura, agendaContactos);

    return facturar;
  }
  private buildFacturarUy(userData: IUserData) {
    const facturar: MenuItem = {
      id: 'facturar',
      label: 'Facturar',
      weight: 0,
      icon: 'calculator-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        facturar.subMenu.show = !facturar.subMenu.show;
      },
    };
    const emitirFactura: SubMenuItem = {
      id: 'emitir_factura',
      label: 'Emitir comprobantes',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/facturar', { queryParams: { reset: 1 } }),
    };

    const personalizarFactura: SubMenuItem = {
      id: 'personalizar_factura',
      label: 'Personalizar',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/personalizar'),
    };

    const agendaContactos: SubMenuItem = {
      id: 'agenda_contactos',
      label: 'Agenda de contactos',
      disabled: false,
      action: () => this.navigateTo('management/contacts'),
    };

    facturar.subMenu.items.push(emitirFactura, personalizarFactura, agendaContactos);

    return facturar;
  }

  /** Gestion **/
  private buildGestionAr(userData: IUserData) {
    const gestion: MenuItem = {
      id: 'gestion',
      label: 'Gestión',
      weight: 1,
      icon: 'file-tray-full-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        (!userData?.is_servicio_contable && !userData.servicio_facturacion),
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        gestion.subMenu.show = !gestion.subMenu.show;
      },
    };

    const verComprobantes: SubMenuItem = {
      id: 'ver_comprobantes',
      label: 'Comprobantes Emitidos',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/comprobantes'),
    };

    const cuentaCorrienteClientes: SubMenuItem = {
      id: 'cuenta_corriente_clientes',
      label: 'Cuenta Corriente',
      disabled: false,
      action: () => this.navigateTo('statistics/current-account'),
    };

    if (this.currentCountry.id === 'ar') {
      const actividades: SubMenuItem = {
        id: 'actividades',
        label: 'Actividades',
        disabled: false,
        action: () => this.navigateTo('management/activities'),
      };

      const comprasRealizadas: SubMenuItem = {
        id: 'compras_realizadas',
        label: 'Compras Realizadas',
        disabled: false,
        action: () => this.navigateTo('management/purchases'),
      };

      gestion.subMenu.items.push(actividades, verComprobantes, cuentaCorrienteClientes, comprasRealizadas);
    }

    return gestion;
  }
  private buildGestionPe(userData: IUserData) {
    const gestion: MenuItem = {
      id: 'gestion',
      label: 'Gestión',
      weight: 1,
      icon: 'file-tray-full-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        gestion.subMenu.show = !gestion.subMenu.show;
      },
    };

    const actividades: SubMenuItem = {
      id: 'actividades',
      label: 'Actividades',
      disabled: false,
      action: () => this.navigateTo('management/activities'),
    };

    const verComprobantes: SubMenuItem = {
      id: 'ver_comprobantes',
      label: 'Comprobantes Emitidos',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/comprobantes'),
    };

    gestion.subMenu.items.push(actividades, verComprobantes);

    return gestion;
  }
  private buildGestionUy(userData: IUserData) {
    const gestion: MenuItem = {
      id: 'gestion',
      label: 'Gestión',
      weight: 1,
      icon: 'file-tray-full-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.servicio_facturacion,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        gestion.subMenu.show = !gestion.subMenu.show;
      },
    };

    const actividades: SubMenuItem = {
      id: 'actividades',
      label: 'Actividades',
      disabled: false,
      action: () => this.navigateTo('management/activities'),
    };

    const verComprobantes: SubMenuItem = {
      id: 'ver_comprobantes',
      label: 'Comprobantes Emitidos',
      disabled: false,
      action: () => this.navigateTo('servicios/facturacion/comprobantes'),
    };

    gestion.subMenu.items.push(actividades, verComprobantes);

    return gestion;
  }

  private buildAprendeAr() {
    const aprende: MenuItem = {
      id: 'aprende',
      label: 'Aprendé',
      weight: 6,
      icon: 'easel-outline',
      disabled: false,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        aprende.subMenu.show = !aprende.subMenu.show;
      },
    };

    const capacitaciones: SubMenuItem = {
      id: 'capacitaciones',
      label: 'Capacitaciones',
      disabled: false,
      action: () => this.navigateTo('training'),
    };

    const monotributo_gpt: SubMenuItem = {
      id: 'monotributo_gpt',
      label: 'Monotributo GPT',
      disabled: false,
      action: () => this.navigateTo('monotribute'),
    };

    if (this.currentCountry.id === 'ar') {
      const tutoriales: SubMenuItem = {
        id: 'tutoriales',
        label: 'Tutoriales',
        disabled: false,
        action: () => this.navigateTo('tutorials'),
      };

      const noticias: SubMenuItem = {
        id: 'noticias',
        label: 'Noticias e información',
        disabled: false,
        action: () => this.navigateTo('news'),
      };

      const quiz: SubMenuItem = {
        id: 'quiz',
        label: 'Trivia',
        disabled: false,
        action: () => this.navigateTo('quiz'),
      };

      aprende.subMenu.items.push(tutoriales, capacitaciones, quiz, monotributo_gpt, noticias);
    }

    return aprende;
  }
  private buildHerramientas() {
    const aprende: MenuItem = {
      id: 'herramientas',
      label: 'Herramientas',
      weight: 6,
      icon: 'construct-outline',
      disabled: false,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        aprende.subMenu.show = !aprende.subMenu.show;
      },
    };

    const calculadora: SubMenuItem = {
      id: 'calculadora',
      label: 'Calculadora',
      disabled: false,
      action: () => this.navigateTo('calculate'),
    };

    const divisas: SubMenuItem = {
      id: 'divisas',
      label: 'Cotizaciones',
      disabled: false,
      action: () => this.navigateTo('divisas'),
    };

    aprende.subMenu.items.push(calculadora, divisas);

    return aprende;
  }
  private buildHerramientas2() {
    const aprende: MenuItem = {
      id: 'beneficios',
      label: 'Beneficios',
      weight: 7,
      icon: 'gift-outline',
      disabled: false,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        aprende.subMenu.show = !aprende.subMenu.show;
      },
    };

    const beneficios: SubMenuItem = {
      id: 'beneficios',
      label: 'Descuentos',
      disabled: false,
      action: () => this.navigateTo('benefits'),
    };

    const referidos: SubMenuItem = {
      id: 'referidos',
      label: 'Referidos',
      disabled: false,
      action: () => this.navigateTo('referidos'),
    };

    aprende.subMenu.items.push(beneficios, referidos);

    return aprende;
  }
  private buildAprendePe() {
    const aprende: MenuItem = {
      id: 'aprende',
      label: 'Aprendé',
      weight: 6,
      icon: 'easel-outline',
      disabled: false,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        aprende.subMenu.show = !aprende.subMenu.show;
      },
    };
    const capacitaciones: SubMenuItem = {
      id: 'capacitaciones',
      label: 'Capacitaciones',
      disabled: false,
      action: () => this.navigateTo('training'),
    };

    if (this.currentCountry.id === 'pe') {
      const tutoriales: SubMenuItem = {
        id: 'tutoriales',
        label: 'Tutoriales',
        disabled: false,
        action: () => this.navigateTo('tutorials'),
      };

      const noticias: SubMenuItem = {
        id: 'noticias',
        label: 'Noticias e información',
        disabled: false,
        action: () => this.navigateTo('news'),
      };

      aprende.subMenu.items.push(tutoriales, capacitaciones, noticias);
    }
    return aprende;
  }
  private buildAprendeUy() {
    const aprende: MenuItem = {
      id: 'aprende',
      label: 'Aprendé',
      weight: 6,
      icon: 'easel-outline',
      disabled: false,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        aprende.subMenu.show = !aprende.subMenu.show;
      },
    };
    const capacitaciones: SubMenuItem = {
      id: 'capacitaciones',
      label: 'Capacitaciones',
      disabled: false,
      action: () => this.navigateTo('training'),
    };

    if (this.currentCountry.id === 'uy') {
      const tutoriales: SubMenuItem = {
        id: 'tutoriales',
        label: 'Tutoriales',
        disabled: false,
        action: () => this.navigateTo('tutorials'),
      };

      const noticias: SubMenuItem = {
        id: 'noticias',
        label: 'Noticias e información',
        disabled: false,
        action: () => this.navigateTo('news'),
      };

      aprende.subMenu.items.push(tutoriales, capacitaciones, noticias);
    }
    return aprende;
  }

  /** Monotributo **/
  private buildMonotributo(userData: IUserData, recategorizacionExtra: boolean) {
    let label = 'Monotributo';

    if (this.currentCountry.id == 'pe') {
      label = 'Nuevo RUS';
    }

    const monotributo: SubMenuItem = {
      id: 'monotributo',
      label,
      disabled: false,
      subMenu: {
        items: [],
        show: true,
      },
      action: () => this.navigateTo('/servicios/monotributo', { state: { isAlta: false } }),
    };

    const alta: SubMenuItem = {
      id: 'alta',
      label: 'Alta',
      disabled: userData?.monotributo?.activo,
      action: () => this.navigateTo('/servicios/monotributo', { state: { isAlta: true } }),
    };
    monotributo.subMenu.items.push(alta);

    const modificacion: SubMenuItem = {
      id: 'modificacion',
      label: 'Modificación',
      disabled: !userData?.monotributo?.activo,
      action: () => this.navigateTo('/servicios/monotributo/modificacion'),
    };
    monotributo.subMenu.items.push(modificacion);

    const baja: SubMenuItem = {
      id: 'baja',
      label: 'Baja',
      disabled: !userData?.monotributo?.activo,
      action: () => this.navigateTo('/servicios/monotributo/baja'),
    };
    monotributo.subMenu.items.push(baja);

    if (userData?.monotributo?.activo && recategorizacionExtra) {
      const recategorizacion: SubMenuItem = {
        id: 'recategorizacion',
        label: 'Recategorización',
        disabled: false,
        action: () => this.navigateTo('/servicios/monotributo/recategorizacion'),
      };
      monotributo.subMenu.items.push(recategorizacion);
    }

    return monotributo;
  }

  /** Ingresos Brutos **/
  private buildIngresosBrutos(userData: IUserData) {
    const navigateIIBB = (sectionPage: SectionPageENUM) => {
      this.navigateTo(`servicios/ingresos-brutos/${sectionPage}`);
    };

    const ingresosBrutos: SubMenuItem = {
      id: 'ingesos_brutos',
      label: 'Ingresos Brutos',
      disabled: !userData?.activo,
      subMenu: {
        items: [],
        show: true,
      },
      action: () => this.navigateTo('/servicios/ingresos-brutos'),
    };

    if (userData?.is_servicio_contable) {
      const alta: SubMenuItem = {
        id: 'alta',
        label: 'Alta',
        disabled: false,
        action: () => navigateIIBB(SectionPageENUM.IIBB_REGISTER),
      };
      ingresosBrutos.subMenu.items.push(alta);

      const baja: SubMenuItem = {
        id: 'baja',
        label: 'Baja',
        disabled: !userData?.ingresos_brutos?.activo,
        action: () => navigateIIBB(SectionPageENUM.IIBB_UNSUBSCRIBE),
      };
      ingresosBrutos.subMenu.items.push(baja);
      if (
        userData?.ingresos_brutos?.tipo_inscripcion_iibb_name !== 'Régimen Unificado' &&
        userData?.ingresos_brutos?.tipo_inscripcion_iibb_name !== 'Régimen Simplificado' &&
        userData?.ingresos_brutos?.tipo_inscripcion_iibb_name !== 'Exento en IIBB' &&
        userData?.ingresos_brutos?.tipo_inscripcion_iibb_name !== 'No quiere alta IIBB'
      ) {
        const presentacionMensual: SubMenuItem = {
          id: 'presentacion_mensual',
          label: 'Presentación mensual',
          disabled: !userData?.ingresos_brutos?.activo,
          action: () => navigateIIBB(SectionPageENUM.IIBB_P_MONTHLY),
        };
        ingresosBrutos.subMenu.items.push(presentacionMensual);

        const presentacionAnual: SubMenuItem = {
          id: 'presentacion_anual',
          label: 'Presentación anual',
          disabled: !userData?.ingresos_brutos?.activo,
          action: () => navigateIIBB(SectionPageENUM.IIBB_P_ANNUAL),
        };
        ingresosBrutos.subMenu.items.push(presentacionAnual);
      }
    }

    return ingresosBrutos;
  }

  /** Mis Impuestos **/
  private buildMisImpuestos(userData: IUserData, recategorizacionExtra: boolean) {
    const subMenuItems = [this.buildMonotributo(userData, recategorizacionExtra)];

    // "Ingresos brutos" solo en Argentina
    if (this.currentCountry.id === 'ar') {
      subMenuItems.push(this.buildIngresosBrutos(userData));
    }

    const misImpuetos: MenuItem = {
      id: 'mis_impuestos',
      label: 'Mis impuestos',
      weight: 2,
      icon: 'document-text-outline',
      disabled:
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.is_servicio_contable,
      show: userData?.is_servicio_contable,
      subMenu: {
        items: subMenuItems,
        show: false,
      },
      action: () => (misImpuetos.subMenu.show = !misImpuetos.subMenu.show),
    };

    return misImpuetos;
  }

  /** Estadisticas **/
  private buildEstadisticasAr(userData: IUserData) {
    const estadisticas: MenuItem = {
      id: 'estadisticas',
      label: 'Estadísticas',
      weight: 3,
      icon: 'pie-chart-outline',
      disabled: !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal && !userData.servicio_facturacion,

      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (estadisticas.subMenu.show = !estadisticas.subMenu.show),
    };

    const ventas: SubMenuItem = {
      id: 'ventas',
      label: 'Ventas',
      disabled: !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal && !userData.servicio_facturacion,
      action: () => this.navigateTo('statistics/sales'),
    };
    estadisticas.subMenu.items.push(ventas);

    // "Costos actividad" solo en Argentina
    // if (this.currentCountry.id === 'ar') {
    //   const costosActividad: SubMenuItem = {
    //     id: 'costos_actividad',
    //     label: 'Costos actividad',
    //     disabled: !userData?.is_servicio_contable,
    //     action: () => this.navigateTo('statistics/costs'),
    //   };
    //   estadisticas.subMenu.items.push(costosActividad);
    // }

    if (this.currentCountry.id === 'ar') {
      const gastosPersonales: SubMenuItem = {
        id: 'gatos_personales',
        label: 'Gastos',
        disabled:
          !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal && !userData.servicio_facturacion,

        action: () => this.navigateTo('statistics/expenses'),
      };
      const gastosPersonales2: SubMenuItem = {
        id: 'gatos_personales',
        label: 'Ganancias',
        disabled:
          !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal && !userData.servicio_facturacion,

        action: () => this.navigateTo('statistics/expenses-total'),
      };

      const ultimosInformes: SubMenuItem = {
        id: 'ultimos_inforemos',
        label: 'Últimos informes',
        disabled: !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal,

        action: () => this.navigateTo('statistics/last-reports'),
      };

      const informe: MenuItem = {
        id: 'informe fiscal',
        label: 'Informe fiscal',
        weight: 8,
        icon: 'bar-chart-outline',
        disabled: !userData?.is_servicio_contable && !userData?.servicio_informe_fiscal,

        show: true,
        action: () => this.navigateTo('tax-report'),
      };

      estadisticas.subMenu.items.push(gastosPersonales, gastosPersonales2, informe, ultimosInformes);
    }

    return estadisticas;
  }
  private buildEstadisticasPe(userData: IUserData) {
    const estadisticas: MenuItem = {
      id: 'estadisticas',
      label: 'Estadísticas',
      weight: 3,
      icon: 'pie-chart-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        (!userData?.is_servicio_contable && !userData?.servicio_informe_fiscal),
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (estadisticas.subMenu.show = !estadisticas.subMenu.show),
    };
    const ventas: SubMenuItem = {
      id: 'ventas',
      label: 'Ventas',
      disabled: !userData?.is_servicio_contable,
      action: () => this.navigateTo('statistics/sales'),
    };
    estadisticas.subMenu.items.push(ventas);
    return estadisticas;
  }
  private buildEstadisticasUy(userData: IUserData) {
    const estadisticas: MenuItem = {
      id: 'estadisticas',
      label: 'Estadísticas',
      weight: 3,
      icon: 'pie-chart-outline',
      disabled:
        !userData?.activo ||
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        (!userData?.is_servicio_contable && !userData?.servicio_informe_fiscal),
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (estadisticas.subMenu.show = !estadisticas.subMenu.show),
    };
    const ventas: SubMenuItem = {
      id: 'ventas',
      label: 'Ventas',
      disabled: !userData?.is_servicio_contable,
      action: () => this.navigateTo('statistics/sales'),
    };
    estadisticas.subMenu.items.push(ventas);
    return estadisticas;
  }

  /** Pagos **/
  private buildPagosAr(userData: IUserData) {
    const pagos: MenuItem = {
      id: 'pagos',
      label: 'Pagos',
      weight: 4,
      icon: 'wallet-outline',
      disabled: !userData.activo || userData?.estado === UDEstateENUM.ESTADO_BAJA || !userData?.is_servicio_contable,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (pagos.subMenu.show = !pagos.subMenu.show),
    };

    if (this.currentCountry.id === 'ar') {
      const pagarMonotributo: SubMenuItem = {
        id: 'pagar_monotributo',
        label: 'Pagar Monotributo',
        disabled: !userData.monotributo?.activo || userData.estado === UDEstateENUM.ESTADO_BLOQUEADO,
        action: async () => {
          this.navigateTo('/payments/estado-monotributo');
          if (userData.monotributo.debito_automatico) {
            const alert = await this.alertController.create({
              header: 'Configuración de Pago',
              message:
                'Tenes configurado el pago del Monotributo por débito automático. La cuota se debitará en la fecha de vencimiento.',
              buttons: ['Cerrar'],
              cssClass: 'custom-alert-style',
            });

            await alert.present();
          }
        },
      };
      pagos.subMenu.items.push(pagarMonotributo);

      if (userData?.ingresos_brutos?.activo) {
        const pagarIngresosBrutos: SubMenuItem = {
          id: 'pagar_ingresos_brutos',
          label: 'Pagar Ingresos Brutos',
          disabled: !userData.ingresos_brutos?.activo || userData.estado === UDEstateENUM.ESTADO_BLOQUEADO,
          action: () => this.navigateTo('/payments/estado-ingresos-brutos'),
        };
        pagos.subMenu.items.push(pagarIngresosBrutos);
      }
    }

    const pagarTributoSimple: SubMenuItem = {
      id: 'pagar_tributo_simple',
      label: 'Pagar Tributo Simple',
      disabled: !userData?.is_servicio_contable,
      action: () => this.navigateTo('/payments/estado-tributo-simple'),
    };
    pagos.subMenu.items.push(pagarTributoSimple);

    return pagos;
  }
  private buildPagosPe(userData: IUserData) {
    const pagos: MenuItem = {
      id: 'pagos',
      label: 'Pagos',
      weight: 4,
      icon: 'wallet-outline',
      disabled: !userData.activo || userData?.estado === UDEstateENUM.ESTADO_BAJA || !userData?.is_servicio_contable,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (pagos.subMenu.show = !pagos.subMenu.show),
    };
    const pagarTributoSimple: SubMenuItem = {
      id: 'pagar_tributo_simple',
      label: 'Pagar Tributo Simple',
      disabled: !userData?.is_servicio_contable,
      action: () => this.navigateTo('/payments/estado-tributo-simple'),
    };
    pagos.subMenu.items.push(pagarTributoSimple);

    return pagos;
  }
  private buildPagosUy(userData: IUserData) {
    const pagos: MenuItem = {
      id: 'pagos',
      label: 'Pagos',
      weight: 4,
      icon: 'wallet-outline',
      disabled: !userData.activo || userData?.estado === UDEstateENUM.ESTADO_BAJA || !userData?.is_servicio_contable,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => (pagos.subMenu.show = !pagos.subMenu.show),
    };
    const pagarTributoSimple: SubMenuItem = {
      id: 'pagar_tributo_simple',
      label: 'Pagar Tributo Simple',
      disabled: !userData?.is_servicio_contable,
      action: () => this.navigateTo('/payments/estado-tributo-simple'),
    };
    pagos.subMenu.items.push(pagarTributoSimple);

    return pagos;
  }

  /** Integraciones **/
  private buildIntegraciones(userData: IUserData) {
    const integraciones: MenuItem = {
      id: 'integraciones',
      label: 'Integraciones',
      weight: 5,
      icon: 'git-compare-outline',
      disabled:
        userData?.estado === UDEstateENUM.ESTADO_BLOQUEADO ||
        userData?.estado === UDEstateENUM.ESTADO_BAJA ||
        !userData?.is_servicio_contable,
      show: true,
      subMenu: {
        items: [],
        show: false,
      },
      action: () => {
        integraciones.subMenu.show = !integraciones.subMenu.show;
      },
    };

    const tiendaNube: SubMenuItem = {
      id: 'tienda_nube',
      label: 'Tiendanube',
      img: '/assets/tiendanube-icon.svg',
      // disabled: !userData.tiene_integrado_tienda_nube,
      disabled: false,
      action: () => this.navigateTo('integrations/tienda-nube'),
    };

    const empreTienda: SubMenuItem = {
      id: 'empre_tienda',
      label: 'Empretienda',
      img: '/assets/empretienda-icon.svg',
      // disabled: !userData.tiene_integrado_tienda_nube,
      disabled: false,
      action: () => this.navigateTo('integrations/empre-tienda'),
    };

    const mercadoLibre: SubMenuItem = {
      id: 'mercado_libre',
      label: 'Mercado Libre',
      img: '/assets/mercadolibre-icon.svg',
      // disabled: !userData.tiene_integrado_mercadolibre,
      disabled: false,
      action: () => this.navigateTo('integrations/mercadolibre'),
    };

    integraciones.subMenu.items.push(mercadoLibre, tiendaNube, empreTienda);

    return integraciones;
  }

  public async buildMenu(): Promise<MenuItem[]> {
    this.currentCountry = await this.currentCountryService.getUserCountry();

    console.log(`buildMenu -> Country: ${this.currentCountry.name}`);

    const menuItems: MenuItem[] = this.buildEssentials();

    const userData = await this.userDataService
      .getUserData()
      .pipe(
        filter((userData) => Object.keys(userData).length > 0),
        take(1)
      )
      .toPromise();

    switch (this.currentCountry.id) {
      case 'ar': {
        const recategorizacionExtra = await this.monotributoService.getFechasRecategorizacion().toPromise();

        menuItems.push(
          this.buildFacturarAr(userData),
          this.buildGestionAr(userData),
          this.buildMisImpuestos(userData, recategorizacionExtra),
          this.buildEstadisticasAr(userData),
          this.buildPagosAr(userData),
          this.buildIntegraciones(userData),
          this.buildAprendeAr(),
          this.buildHerramientas(),
          this.buildHerramientas2()
        );
        break;
      }
      case 'pe': {
        menuItems.push(
          this.buildFacturarPe(userData),
          this.buildGestionPe(userData),
          this.buildEstadisticasPe(userData),
          this.buildPagosPe(userData),
          this.buildAprendePe()
        );
        break;
      }
      case 'uy': {
        menuItems.push(
          this.buildFacturarUy(userData),
          this.buildGestionUy(userData),
          this.buildEstadisticasUy(userData),
          this.buildPagosUy(userData),
          this.buildAprendeUy()
        );
        break;
      }
    }

    return menuItems.sort((a, b) => a.weight - b.weight);
  }
}
