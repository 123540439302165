import { Component, Input } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { HomeSlide } from 'src/app/interfaces/home-slide.interface';

import { NavigationService } from 'src/app/services/navigation.service';
import { IUserData, UDEstateENUM } from 'src/app/services/user/user-data.interface';

@Component({
  selector: 'app-slides-ar',
  templateUrl: './slides.component.html',
  styleUrls: ['./slides.component.scss'],
})
export class SlidesArComponent implements HomeSlide {
  @Input() userData: IUserData;
  @Input() dateNow: Date;
  mobile: boolean;
  udeStates = UDEstateENUM;

  slideOpts = {
    initialSlide: 0,
    speed: 400,
  };
  constructor(private navigationService: NavigationService) {}
  async ngOnInit() {
    this.mobile = false;

    if (this.isMobile()) {
      this.mobile = true;
    } else {
      this.mobile = false;
    }
  }

  goToFacturaFacil() {
    this.navigationService.navigateRoot('/servicios/facturacion/facturar_facil' , { queryParams: { reset: 1 } });
  }
  goToVentas() {
    this.navigationService.navigateRoot('/statistics/sales');
  }
  goToGastos() {
    this.navigationService.navigateRoot('/statistics/expenses');
  }
  goToInformeFiscal() {
    this.navigationService.navigateRoot('/referidos');
  }
  goToAsesor() {
    const navigationExtras: NavigationExtras = {
      state: {
        contactarAsesor: true,
      },
    };
    this.navigationService.navigateForward('/contact', navigationExtras);
  }
  goToAprender() {
    this.navigationService.navigateRoot('/training');
  }
  private isMobile(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
}
